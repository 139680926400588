import { BoxIso, LogOut, OpenBook, Star } from 'iconoir-react';
import React, { Fragment } from 'react';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import { useLogout } from '../../../../lib/hooks/use-logout';

const MobileNav = ({ setOpen }: { setOpen: (b: boolean) => void }) => {
  const { ct } = useCatalogTranslation('common');
  const { path, redirect, logoutAndRefresh } = useLogout();
  const mobileMenu = [
    {
      label: ct('CommonNavbar.catalog', 'Catalogue'),
      path: '/products',
      icon: <OpenBook className="h-4 w-4" />,
      callback: redirect,
    },
    {
      label: ct('CommonNavbar.orders'),
      path: '/orders',
      icon: <BoxIso className="h-4 w-4" />,
      callback: redirect,
    },
    {
      label: ct('CommonNavbar.favorites'),
      path: '/favorite-lists',
      icon: <Star className="h-4 w-4" />,
      callback: redirect,
    },
    {
      label: ct('CommonNavbar.logout', 'Se déconnecter'),
      path: path,
      textColor: 'text-error-50',
      icon: <LogOut className="h-4 w-4 text-error-50" />,
      callback: logoutAndRefresh,
    },
  ];

  return (
    <nav className="flex flex-1 flex-col" aria-label="Sidebar">
      <ul role="list" className="-mx-2 space-y-1">
        {mobileMenu.map((item, i) => (
          <Fragment key={item.label}>
            {i === mobileMenu.length - 1 && (
              <div className="w-full h-[1px] bg-grey2-10"></div>
            )}
            <li className="py-3  px-6">
              <button
                onClick={() => {
                  setOpen(false);
                  item.callback(item.path);
                }}
                className="flex items-center gap-2"
              >
                <span>{item.icon}</span>
                <span className={item.textColor}>{item.label}</span>
              </button>
            </li>
          </Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default MobileNav;
