import React, { Fragment, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { ICartItem } from '../../../../lib/models/view-models/cart';
import { useStore } from '../../../../lib/context/store-context';
import { useCartDropdown } from '../../../../lib/context/cart-dropdown-context';
import { Popover, Transition } from '@headlessui/react';
import { Cart, Cancel, Bag } from 'iconoir-react';
import CatalogButton from '../../../common/components/catalog-button';
import Image from 'next/image';
import Franco from '../../../cart/templates/franco';
import { useAccount } from '../../../../lib/context/account-context';

const CartPopover = ({ displayButton }: { displayButton: boolean }) => {
  const { ct } = useCatalogTranslation('common');
  const [item, setItem] = useState<ICartItem>();
  const { cart, totalItems } = useStore();
  const { state, close, lastItemId } = useCartDropdown();
  const { company } = useAccount();

  useEffect(() => {
    if (cart) {
      const lastItem = cart.items.find((item) => item.variantId === lastItemId);
      setItem(lastItem);
    }
    return () => setItem(null);
  }, [lastItemId]);

  const freeShipping = useMemo(
    () => cart?.discounts?.some((d) => d.type === 'free_shipping'),
    [cart],
  );

  return (
    cart &&
    totalItems > 0 && (
      <div className="z-50" data-testid="cart-popover">
        <Popover className="relative">
          <Popover.Button
            className={clsx('flex h-full flex-row', !displayButton && 'hidden')}
          >
            <Link href="/cart" passHref>
              <div
                data-testid="cart-icon-button"
                className={clsx(
                  'flex h-8 items-center justify-center rounded-3xl text-sm font-semibold',
                  totalItems > 0
                    ? 'px-3 bg-seller text-sellerFont'
                    : 'w-8 bg-seller/10 text-seller',
                )}
                onClick={close}
              >
                <Cart className="h-4 w-4 stroke-2" />
                {totalItems > 0 && (
                  <span className="ml-2">{`${totalItems}`}</span>
                )}
              </div>
            </Link>
          </Popover.Button>
          <Transition
            show={state}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className="fixed top-[60px] right-4 hidden w-[360px] min-h-[180px] bg-white shadow text-gray-900 sm:block border rounded-xl border-grey2-10 "
            >
              <div className="flex flex-col gap-4 p-4">
                <div className="flex gap-4">
                  <div className="relative h-16 w-16 rounded-xl border border-grey2-5 shadow-xs">
                    <Image
                      src={item?.thumbnail}
                      className="rounded-xl aspect-square w-full h-auto object-contain"
                      width={64}
                      height={64}
                      alt={item?.description}
                    />
                    <div className="absolute top-0 h-6 min-w-6 left-full transition-transform duration-700 -translate-y-1/2 -translate-x-4  text-white bg-seller rounded-full flex justify-center items-center p-2">
                      {item?.quantity}
                    </div>
                  </div>
                  <div className="w-[280px] flex gap-1 ">
                    <p className="text-base">
                      <strong>{item?.title}</strong>{' '}
                      {ct(
                        'CartElement.addedToCart',
                        'a bien été ajouté au panier !',
                      )}{' '}
                    </p>
                    <button
                      className="min-w-[32px] rounded-full bg-grey2-10 h-8 w-8 flex items-center justify-center"
                      onClick={close}
                    >
                      <Cancel className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                <div>
                  <Franco
                    franco={company?.franco}
                    minOrderAmount={company?.minOrderAmount}
                    total={cart.subtotal}
                    fullVersion={false}
                    freeShipping={freeShipping}
                  />
                </div>
                <div>
                  <Link href="/cart" passHref>
                    <CatalogButton color="seller" size="medium">
                      <Bag />
                      <span>
                        {ct('CommonCta.seeCart', 'Voir panier')} .{' '}
                        {cart?.items.length} {ct('Common.refId').toLowerCase()}
                      </span>
                    </CatalogButton>
                  </Link>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    )
  );
};

export default CartPopover;
