import React, { ReactNode, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

type TooltipProps = {
  children: ReactNode;
  content: ReactNode;
  x_position?: 'left' | 'right';
  y_position?: 'top' | 'bottom';
  textAlign?: 'left' | 'center' | 'right';
};

const CatalogTooltip = ({
  children,
  content,
  x_position = 'right',
  y_position = 'bottom',
  textAlign = 'center',
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible && triggerRef.current && tooltipRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      let top, left;

      // Calculer la position en fonction des props x_position et y_position
      if (y_position === 'top') {
        top = triggerRect.top - tooltipRect.height;
      } else {
        top = triggerRect.bottom;
      }

      if (x_position === 'left') {
        left = triggerRect.left - (tooltipRect.width - triggerRect.width);
      } else {
        left = triggerRect.left;
      }

      // Prendre en compte le scroll vertical
      top += window.scrollY;
      left += window.scrollX;

      setPosition({ top, left });
    }
  }, [isVisible, x_position, y_position]);

  const tooltip = (
    <div
      ref={tooltipRef}
      className="absolute z-50 w-fit rounded bg-gray-900 p-2 text-xs text-white shadow-lg transition-opacity duration-300"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        textAlign: textAlign,
        opacity: isVisible ? 1 : 0,
      }}
    >
      {content}
    </div>
  );

  return (
    <div
      ref={triggerRef}
      className="relative inline-block group"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && ReactDOM.createPortal(tooltip, document.body)}
    </div>
  );
};

export default CatalogTooltip;
