import Image from 'next/image';
import clsx from 'clsx';
import Truncate from '../../../../lib/utils/helpers/truncate-string';
import React, { useState } from 'react';
import { ArrowRight } from 'iconoir-react';

type CategoryCardProps = {
  categoryId?: string;
  categoryHandle?: string;
  img: string;
  categoryTitle: string;
  cardClicked?: ({ id, handle }: { id: string; handle: string }) => void;
  size: 'sm' | 'md';
};

function CategoryCard({
  categoryId,
  img,
  categoryTitle,
  categoryHandle,
  cardClicked,
  size,
}: CategoryCardProps): JSX.Element {
  const [src, setSrc] = React.useState(img);
  const [arrowDisplayed, setArrowDisplayed] = useState(false);
  const updateImageSrcWhenError = () => {
    setSrc('/placeholder.png');
  };
  return (
    <div
      className="relative cursor-pointer flex h-auto w-full flex-col gap-0 rounded-xl shadow-sm border border-grey2-10 mx-auto hover:shadow-lg"
      onClick={() => cardClicked({ id: categoryId, handle: categoryHandle })}
      onMouseEnter={() => setArrowDisplayed(true)}
      onMouseLeave={() => setArrowDisplayed(false)}
    >
      <div className="relative h-auto aspect-square w-full overflow-hidden rounded-t-3xl border-b border-grey2-10">
        <Image
          src={src || '/placeholder.png'}
          width={500}
          height={500}
          alt={categoryTitle}
          className="object-contain w-full h-auto"
          placeholder="blur"
          blurDataURL="/placeholder.png"
          onError={() => {
            updateImageSrcWhenError();
          }}
        />
      </div>
      <div className="flex h-[56px] items-center justify-between rounded-b-3xl py-4 px-3 text-grey2-90">
        <div
          className={clsx('group relative text-left text-grey2-90', {
            'font-sans text-base leading-5 font-semibold': size === 'sm',
            'text-lg font-header font-semibold': size === 'md',
          })}
        >
          <Truncate input={categoryTitle} maxLength={19} />
        </div>
        {size === 'md' && (
          <button
            className={clsx('transition-opacity', {
              'opacity-0': !arrowDisplayed,
              'opacity-100': arrowDisplayed,
            })}
          >
            <ArrowRight className="h-6 w-6 text-grey2-90" />
          </button>
        )}
      </div>
    </div>
  );
}

export default CategoryCard;
