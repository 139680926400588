import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import { Box, LogOut, NavArrowDown } from 'iconoir-react';
import { Menu, Transition } from '@headlessui/react';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { useLogout } from '../../../../lib/hooks/use-logout';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

type MenuItem = {
  label: string;
  path: string;
  icon: React.ReactNode;
  textColor: string;
  callback: (path: string) => void;
};

const MoreDropdown = () => {
  const { ct } = useCatalogTranslation('common');
  const { i18n } = useTranslation();
  const iconClasses = 'w-4 h-4';
  const { path, logoutAndRefresh } = useLogout();
  const router = useRouter();
  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        label: ct('CommonNavbar.backOrders', 'Reliquats'),
        path: '/backorders',
        textColor: 'text-grey2-60',
        icon: <Box className={`${iconClasses} text-grey2-80`} />,
        callback: () => router.push('/backorders'),
      },
      // {
      //   label: ct('CommonNavbar.settings'),
      //   path: '/',
      //   textColor: 'text-grey2-60',
      //   icon: <Settings className={`${iconClasses} text-grey2-80`} />,
      //   callback: redirect,
      // },
      {
        label: ct('CommonNavbar.logout', 'Se déconnecter'),
        path: path,
        textColor: 'text-error-50',
        icon: <LogOut className={`${iconClasses} text-error-50`} />,
        callback: logoutAndRefresh,
      },
    ],
    [path, i18n.language],
  );

  return (
    <Menu as="div" className="hidden relative md:inline-block text-left">
      {({ open, close }) => (
        <>
          <div>
            <Menu.Button
              className={clsx(
                'inline-flex w-full justify-center items-center gap-2 rounded-md px-3 py-2 text-sm leading-5 text-grey2-60 hover:bg-grey2-5',
                open ? 'bg-grey2-5' : 'bg-white',
              )}
            >
              <span className="hidden sm:block">
                {ct('CommonNavbar.more', 'Plus')}
              </span>
              <NavArrowDown className="w-4 h-4 text-grey2-60 font-semibold hidden sm:block" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {menuItems?.map((item, i) => (
                  <div
                    key={item.label}
                    className={clsx(
                      'flex items-center h-10 px-4 gap-2 cursor-pointer hover:bg-grey2-5',
                      item.textColor,
                      {
                        'border-t border-grey2-10':
                          menuItems.length > 1 && i === menuItems.length - 1,
                      },
                    )}
                    onClick={() => {
                      close();
                      item.callback(item.path);
                    }}
                  >
                    {item.icon}
                    <span className="text-base leading-5">{item.label}</span>
                  </div>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default MoreDropdown;
