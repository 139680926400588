import { useQuery } from '@tanstack/react-query';
import {
  getProductCategories,
  getProductCategoryById,
  ProductCategoryQueryParams,
} from '@lib/data/product-category-data';

import {
  buildFullProductCategory,
  buildProductCategories,
} from '../utils/products/product-categories';

export const useProductCategories = ({
  expand,
  parent_category_id,
  fields,
}: ProductCategoryQueryParams) => {
  const queryKey = [
    'productCategories',
    { expand, parent_category_id, fields } as ProductCategoryQueryParams,
  ];
  const {
    data: categories,
    isError,
    isLoading,
  } = useQuery(queryKey, getProductCategories, {
    select: buildProductCategories,
  });

  return { categories, isError, isLoading } as const;
};

export const useProductCategory = (
  id: string,
  fields: string,
  expand: string,
) => {
  const queryKey = [
    'productCategory',
    { parent_category_id: id, fields, expand } as ProductCategoryQueryParams,
  ];
  const { data } = useQuery(queryKey, getProductCategoryById, {
    select: buildFullProductCategory,
  });

  return { ...data } as const;
};
