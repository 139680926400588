import NextHead from 'next/head';
import React from 'react';

type HeadProps = {
  title?: string;
  description?: string | null;
  image?: string | null;
  style?: string | null;
};

const Head: React.FC<HeadProps> = ({ title, description, image, style }) => {
  return (
    <NextHead>
      <title>{`${title} | Catalog`}</title>
      <meta itemProp="name" content={title} />
      {description && <meta itemProp="description" content={description} />}
      {image && <meta itemProp="image" content={image} />}
      {style && <style>{style}</style>}
      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  );
};

export default Head;
