import currency from 'currency.js';

export interface ICurrencyOptions {
  symbol?: string;
  separator?: string;
  decimal?: string;
  precision?: number;
  pattern?: string;
  negativePattern?: string;
}

const defaultCurrencyOptions: ICurrencyOptions = {
  symbol: '€',
  separator: ' ',
  decimal: ',',
  precision: 2,
  pattern: '# ! ',
  negativePattern: '-# ! ',
};

export const EURO = (value: number) => manageCurrency(value);

export const customPrice = (value: number, options: ICurrencyOptions) =>
  manageCurrency(value, { ...defaultCurrencyOptions, ...options });

const manageCurrency = (
  value: number,
  options: ICurrencyOptions = defaultCurrencyOptions,
) => {
  if (!isFinite(value) || value === null) return '';
  const { separator, decimal, symbol, pattern, negativePattern } = options;
  const rounded = roundTo(
    value,
    parseInt(process.env.NEXT_PUBLIC_DECIMAL_NUMBER, 10) || 6,
  );
  const precision = Math.max(options.precision, decimalCount(rounded));
  const formatedValue = currency(rounded, {
    separator,
    decimal,
    symbol,
    precision,
    pattern,
    negativePattern,
  });
  return formatedValue.format();
};

const decimalCount = (value: number) =>
  value.toString().split('.')[1]?.length || 0;

const roundTo = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
};
