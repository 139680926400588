import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCatalogTranslation } from '../../hooks/use-catalog-translations';
import CatalogTooltip from '../../../modules/common/components/catalog-tooltip';

type TruncateProps = {
  input: string;
  maxLength: number;
  seeMore?: boolean;
};

const Truncate: React.FC<TruncateProps> = ({
  input,
  maxLength,
  seeMore = false,
}): JSX.Element => {
  const { ct } = useCatalogTranslation('common');
  const { i18n } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [buttonLabel, setButtonLabel] = useState<string>(null);
  const output =
    input?.length > maxLength
      ? `${input.substring(0, maxLength - 3)}...`
      : input;

  useEffect(() => {
    const label = collapsed
      ? ct('Common.readMore', 'Voir plus')
      : ct('Common.seeLess', 'Voir moins');
    setButtonLabel(label);
  }, [collapsed, i18n.language]);
  return !seeMore ? (
    <span className="group/tooltip relative">
      {input?.length > maxLength ? (
        <CatalogTooltip content={input}>{output}</CatalogTooltip>
      ) : (
        output
      )}
    </span>
  ) : collapsed ? (
    <div className="flex items-center gap-5">
      {output}{' '}
      {input?.length > maxLength && (
        <button
          className="cursor-pointer text-seller"
          onClick={() => setCollapsed(!collapsed)}
        >
          {buttonLabel}
        </button>
      )}
    </div>
  ) : (
    <div className="flex items-center gap-5 max-w-[90%]">
      {input}{' '}
      <button
        className="cursor-pointer text-seller min-w-[100px]"
        onClick={() => setCollapsed(!collapsed)}
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default Truncate;
