import { QueryFunction } from '@tanstack/react-query';
import { catalogRequest } from '../config';
import { IStandardApiResponse } from '../models/api/standard-api-response';
import {
  ProductCategoryApiSchema,
  ProductCategoryFromApi,
} from '@lib/models/api/product-category-from-api';

export type ProductCategoryQueryParams = {
  fields?: string;
  parent_category_id?: string;
  expand?: string;
};

export type ProductCategoriesResponse = IStandardApiResponse & {
  product_categories: ProductCategoryFromApi[];
};

export type ProductCategoryResponse = IStandardApiResponse & {
  product_categories: ProductCategoryFromApi;
};

export const getProductCategories: QueryFunction<
  ProductCategoryFromApi[],
  ['productCategories', ProductCategoryQueryParams]
> = async ({ queryKey }) => {
  let path = 'store/product-categories';
  path = buildPath(path, queryKey[1]);
  path += '&parent_category_id=null';
  const response = await catalogRequest<ProductCategoriesResponse>(
    'get',
    path,
  ).then((res) => res.data);
  const parsedResponse = response.product_categories.map((productCategory) =>
    ProductCategoryApiSchema.parse(productCategory),
  );
  return parsedResponse;
};

export const getProductCategoryById: QueryFunction<
  ProductCategoryFromApi,
  ['productCategory', ProductCategoryQueryParams]
> = async ({ queryKey }) => {
  const { parent_category_id: id } = queryKey[1];
  if (!id) {
    return null;
  }
  let path = `store/product-categories/${id}`;
  path = buildPath(path, queryKey[1]);
  const response = await catalogRequest<{
    product_category: ProductCategoryFromApi;
  }>('get', path).then((res) => res.data);

  const parsedResponse = ProductCategoryApiSchema.parse(
    response.product_category,
  );

  return parsedResponse;
};

const buildPath = (url: string, params: ProductCategoryQueryParams) => {
  const { fields, expand } = params;
  let path = url;
  let isFirst = true;
  if (expand !== undefined && expand !== null) {
    path += isFirst ? `?expand=${expand}` : `&expand=${expand}`;
    isFirst = false;
  }
  if (fields !== undefined && fields !== null) {
    path += isFirst ? `?fields=${fields}` : `&fields=${fields}`;
    isFirst = false;
  }

  return path;
};
