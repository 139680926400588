import { z } from 'zod';

const BaseCategoryApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
  name: z.nullable(z.string()).optional(),
  thumbnail: z.nullable(z.string()).optional(),
  handle: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.object({})).optional(),
});

export type ProductCategoryFromApi = z.infer<typeof BaseCategoryApiSchema> & {
  category_children?: ProductCategoryFromApi[];
};

export const ProductCategoryApiSchema: z.ZodType<ProductCategoryFromApi> =
  BaseCategoryApiSchema.extend({
    category_children: z.lazy(() =>
      z.nullable(z.array(ProductCategoryApiSchema)).optional(),
    ),
  });
