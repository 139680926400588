import { ProductCategoriesResponse } from '../../data/product-category-data';
import { ProductCategoryFromApi } from '../../models/api/product-category-from-api';
import { IStandardApiResponse } from '../../models/api/standard-api-response';
import { IProductCategory } from '../../models/view-models/product-category';

export const buildProductCategoriesEntities = (
  data: ProductCategoriesResponse,
): IStandardApiResponse & {
  productCategoryEntities: Record<string, ProductCategoryFromApi>;
} => {
  const productCategoryEntities: Record<string, ProductCategoryFromApi> = {};
  data.product_categories.forEach((o) => {
    productCategoryEntities[o.id] = o;
  });
  return { ...data, productCategoryEntities };
};

export const buildProductCategories = (
  productCategories: ProductCategoryFromApi[],
): IProductCategory[] => {
  const categories = productCategories.map(buildFullProductCategory);
  categories.forEach(setParentCategoryInChildren);
  return categories;
};

export const buildFullProductCategory = (
  productCategory: ProductCategoryFromApi,
): IProductCategory => {
  return productCategory
    ? {
        id: productCategory.id,
        createdAt: productCategory.created_at
          ? new Date(productCategory.created_at)
          : null,
        updatedAt: productCategory.updated_at
          ? new Date(productCategory.updated_at)
          : null,
        name: productCategory.name,
        handle: productCategory.handle,
        thumbnail: productCategory.thumbnail,
        categoryChildren: productCategory.category_children?.map((o) =>
          buildFullProductCategory(o),
        ),
      }
    : null;
};

const setParentCategoryInChildren = (
  productCategory: IProductCategory,
): void => {
  productCategory.categoryChildren?.forEach((child) => {
    child.parentCategory = productCategory;
    setParentCategoryInChildren(child);
  });
};
