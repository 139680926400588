import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

const Logo = () => {
  const logo = `${process.env.NEXT_PUBLIC_ASSETS_BUCKET_URL}/logo.webp`;
  return (
    <div data-testid="seller-logo" className="relative h-16 w-32">
      {logo && (
        <Link href="/">
          <span>
            <Image
              src={logo}
              layout="fill"
              alt="logo"
              objectFit="contain"
              quality={90}
              className="cursor-pointer"
            />
          </span>
        </Link>
      )}
    </div>
  );
};

export default Logo;
