import { WarningCircle } from 'iconoir-react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MEDUSA_LOGOUT } from '@lib/config';
import { useAccount } from '@lib/context/account-context';
import { logout } from '@lib/data/customer-data';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';

const OnBehalfBanner = () => {
  const { company } = useAccount();
  const { ct } = useCatalogTranslation('common');
  const { t } = useTranslation('common');
  const router = useRouter();
  const [rootUrl, setRootUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRootUrl(`${window.location.protocol}//${window.location.host}`);
    }
  }, []);

  return (
    company && (
      <div className="min-h-16 w-full flex items-center justify-start bg-orange-10 text-primary-50 px-4 py-1 gap-6">
        <WarningCircle className="h-6 w-6 text-primary-50" />
        <div className="text-[16px] text-primary-60 font-semibold">
          {ct('Common.adminAccess', 'Accès admin')}
        </div>
        <div className="text-[16px] text-primary-50 bg-white rounded-xl inline-flex h-auto min-h-6 px-2 items-center justify-center ">
          {company.name}
        </div>
        <div className="w-fit">
          <Trans
            t={t}
            i18nKey="Common.adminAccessDesc"
            values={{
              companyName: company.name,
              city: company.billingAddress.city,
              code: company.code,
            }}
          >
            <p>
              Vous êtes actuellement connecté sur l’espace client de
              <strong>
                {company.name} - {company.billingAddress.city} - {company.code}
              </strong>
              . <br />
              Toute action réalisée sur le compte impactera le compte de votre
              client.
            </p>
          </Trans>
        </div>
        <div className="ml-auto">
          <button
            className="text-primary-50 hover:text-primary-60 text-base underline"
            onClick={async () => {
              await logout();
              const path = `${MEDUSA_LOGOUT}?returnTo=${rootUrl}`;
              router.push(path);
            }}
          >
            {ct('CommonCta.logout')}
          </button>
        </div>
      </div>
    )
  );
};

export default OnBehalfBanner;
