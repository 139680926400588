import { useEffect, useRef, useState } from 'react';
import CartFranco from '../components/franco';
import MinOrder from '../components/min-order';

const Franco = ({
  franco,
  total,
  freeShipping,
  minOrderAmount,
  fullVersion = true,
}: {
  franco: number;
  total: number;
  freeShipping: boolean;
  minOrderAmount?: number;
  fullVersion?: boolean;
}) => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(
    progressBarRef.current ? progressBarRef?.current?.offsetWidth : 0,
  );
  const francoActive = franco >= 0 || freeShipping;
  const minOrderActive =
    minOrderAmount > 0 && (total < minOrderAmount || !francoActive);

  useEffect(() => {
    if (progressBarRef.current) {
      setWidth(progressBarRef.current.offsetWidth);
    }
  }, [progressBarRef]);
  const [progressBar, setProgressBar] = useState('4px');

  useEffect(() => {
    const ratio = minOrderActive
      ? getMinOrderRatio(minOrderAmount, total)
      : getFrancoRatio(freeShipping, total, franco);
    let newWidth = width * ratio;
    if (newWidth >= width) {
      newWidth = width;
    }
    if (newWidth >= 360) {
      newWidth = 360;
    }

    setProgressBar(`${newWidth}px`);
  }, [franco, total, width, freeShipping, minOrderActive]);

  const getFrancoRatio = (
    freeShipping: boolean,
    total: number,
    franco: number,
  ) => {
    return freeShipping ? 1 : total / franco;
  };
  const getMinOrderRatio = (minOrderAmount: number, total: number) => {
    return total / minOrderAmount;
  };

  return (
    (francoActive || minOrderActive) && (
      <section>
        <div
          className="h-[2px] min-w-[4px] self-start bg-seller transition-all hidden lg:block"
          style={{ width: progressBar }}
        ></div>
        <div ref={progressBarRef} className="grid w-full grid-cols-1 gap-y-6">
          {minOrderActive ? (
            <MinOrder
              minOrderAmount={minOrderAmount}
              total={total}
              fullVersion={fullVersion}
            />
          ) : (
            <CartFranco
              franco={franco}
              total={total}
              fullVersion={fullVersion}
              freeShipping={freeShipping}
            />
          )}
        </div>
      </section>
    )
  );
};

export default Franco;
