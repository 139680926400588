import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { MEDUSA_LOGOUT } from '../config';
import { logout } from '../data/customer-data';

export const useLogout = () => {
  const [path, setPath] = useState(null);
  const router = useRouter();

  const redirect = (path: string) => {
    router.push(path);
  };

  const logoutAndRedirectOnBehalf = async (path: string) => {
    await logout();
    redirect(path);
  };

  const logoutAndRefresh = async () => {
    await logout();
    router.reload();
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const rootUrl = `${window.location.protocol}//${window.location.host}`;
      setPath(`${MEDUSA_LOGOUT}?returnTo=${rootUrl}`);
    }
  }, []);

  return {
    path,
    redirect,
    logoutAndRedirectOnBehalf,
    logoutAndRefresh,
  } as const;
};
