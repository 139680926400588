import React, { useEffect } from 'react';
import { useAccount } from '../../../lib/context/account-context';
import CatalogNav from './catalog-nav';
import OnBehalfBanner from './on-behalf-banner';

type CatalogLayoutProps = {
  logo?: string;
  children: React.ReactNode;
};
function CatalogLayout({ children }: CatalogLayoutProps): JSX.Element {
  const { customer } = useAccount();
  const [isOnBehalf, setIsOnBehalf] = React.useState<boolean>(false);

  useEffect(() => {
    if (customer) setIsOnBehalf(!!customer.originalEmail);
  }, [customer]);
  return (
    <div className="flex min-h-[100vh] flex-col">
      {isOnBehalf && <OnBehalfBanner />}
      <CatalogNav />
      <main className="relative antialiased overflow-hidden w-full grow">
        {children}
      </main>
    </div>
  );
}

export default CatalogLayout;
